import React from "react"
import SEO from "../components/seo"
import styled from "styled-components"
import Link from "gatsby-link"
import notFound from "../images/404.svg"

const NotFoundPage = () => (
    <Message>
        <SEO title="404: Not found" />
        <figure>
            <img
                width="400"
                alt="Ilustration By https://undraw.co/"
                src={notFound}
            />
            <figcaption
                css={{
                    fontStyle: `italic`,
                    fontSize: `10px`,
                    textAlign: `center`,
                }}
            >
                Ilustration By <a href={`https://undraw.co/`}>undraw.com</a>
            </figcaption>
        </figure>
        <p>Lo sentimos, la página o recurso solicitado no existe.</p>
        <GoBackHome to="/">Volver al inicio</GoBackHome>
    </Message>
)

export default NotFoundPage

const Message = styled.div`
    max-width: 80rem;

    margin: auto;
    text-align: center;
    padding: 50px;
    @media (min-width: 992px) {
        padding: 10rem;
    }
`

const GoBackHome = styled(Link)`
    background: #1b2936;
    color: #fff;
    font-size: 16px;
    font-family: "Raleway", sans-serif;
    padding: 12px 45px;
    text-decoration: none;
    transition: all ease-in 0.3s;
    border-radius: 50px;
    &:hover {
        background: #122636;
    }
`
